<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование класса"
        update
        url-update="/group/update/:id"
        url-back="/group"
        action-one="group/one"
        :fields="fields"
    >
        <group-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import GroupForm from '@/components/forms/GroupForm.vue'
export default {
    name: 'UserUpdate',
    components: { FormView, GroupForm },
    computed: {
        fields () {
            return [ 'id', 'active', 'student_id', 'teacher_id', 'teachers', 'name', 'grade']
        }
    }
}
</script>